/* Import necessary libraries and styles */
import React from 'react'; /* This imports the main React library */
import './AboutSection.css'; /* This imports the styles for this component */
import content from './content.json'; /* This imports the content for this component from a JSON file */

/* This function defines a React component called "AboutSection" */
function AboutSection() {
  /* The "return" statement describes what will be displayed by this component */
  return (
    /* This is a div element with class "about-section". This class can be styled in "AboutSection.css". */
    <div className="about-section">
      {/* This is a heading with class "about-title". It displays the title from our imported content. */}
      <h2 className="about-title">{content.about.title}</h2>
      {/* This is a paragraph with class "about-text". It displays the text from our imported content. */}
      <p className="about-text">{content.about.description}</p>
    </div>
  );
}

/*This line makes the AboutSection component available to other files. This is necessary to use this component in other parts of the app. */
export default AboutSection;