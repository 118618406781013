import React, { useState } from 'react';
import './DashboardContent.css';

function DashboardContent() {
  const [mode, setMode] = useState('upload');  
  const [classification, setClassification] = useState(''); 
  const [inputText, setInputText] = useState('');
  const [apiMode, setApiMode] = useState('basic');  // New state variable

  const handleDetect = () => {
    console.log("Detect button clicked");
  
    // Count the number of words in inputText
    const wordCount = inputText.split(/\s+/).length;
  
    // Check if there are less than 100 words
    if (wordCount < 100) {
      setClassification("Content must be at least 100 words");
      return;
    }
      console.log("Detect button clicked")
    const payload = {
      "data": [ inputText ]
    };

    if (apiMode === 'basic') {
      // Call the basic API
      fetch('https://sumptracvconsma-roberta-base-openai-detector.hf.space/run/predict', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
      .then(response => response.json())
      .then(data => {
        console.log("API response received");
        console.log("First item of API response data:", data.data[0]);
        if (data && data.data && data.data.length > 0 && 'confidences' in data.data[0]) {
          let realConfidence = data.data[0].confidences.find(confidence => confidence.label === 'LABEL_1');
          if (realConfidence) {
            setClassification(`Human Rating: ${(realConfidence.confidence * 100).toFixed(2)}% Human`);
          } else {
            setClassification("Human Rating: No 'Real' label found in the API response.");
          }
        } else {
          setClassification("Human Rating: No 'confidences' found in the API response.");
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
    } else {
      // Call the advanced API
      fetch('https://api.originality.ai/api/v1/scan/ai', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-OAI-API-KEY': 'r8x79h13f02cjsqzvwpmn5kytlabd4io',  // Use your API key here
        },
        body: JSON.stringify({
          "title": "User input",
          "content": inputText,
        }),
      })
      .then(response => response.json())
      .then(data => {
        console.log("API response received");
        console.log("API response data:", data);
        if (data && data.success) {
          setClassification(`AI Rating: ${(data.score.ai * 100).toFixed(2)}% AI Generated`);
        } else {
          setClassification("AI Rating: Error in API response.");
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
    }
  };

  return (
    <div className="main-content">
      <div className="button-group">
        <button className={`button ${mode === 'upload' ? 'active' : ''}`} onClick={() => setMode('upload')}>Upload</button>
        <button className={`button ${mode === 'text' ? 'active' : ''}`} onClick={() => setMode('text')}>Text</button>
        <button className={`button ${apiMode === 'basic' ? 'active' : ''}`} onClick={() => setApiMode('basic')}>Basic</button>
        <button className={`button ${apiMode === 'advanced' ? 'active' : ''}`} onClick={() => setApiMode('advanced')}>Advanced</button>
      </div>
      <div className={`inner-content ${mode}`}>
        {mode === 'upload' ? 
          <p><span>Choose a file</span> or drag it here</p> : 
          <textarea 
            placeholder="Enter your text here" 
            value={inputText} 
            onChange={(e) => setInputText(e.target.value)} 
          ></textarea>
        }
      </div>
      <button className="button detect-now" onClick={handleDetect}>Detect Now</button>
      <p>{classification}</p>
    </div>
  );
}

export default DashboardContent;
