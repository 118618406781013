import React from 'react';
import './TOS.css'; // Import the CSS file here
import Footer from './Footer';

const PrivacyPolicy = () => {
    return (
        <div className="tos">
<h1>Ackergill Institute Privacy Policy</h1>
<p>
Effective Date: [June 8, 2023]
</p>
<p>
This Privacy Policy ("Policy") explains how Ackergill Institute ("Ackergill," "We," "Us," or "Our") collects, uses, discloses, and protects the information that You ("User," "You," or "Your") provide while accessing and using our AI Detection Platform ("Platform"). By using the Platform, You acknowledge that You have read, understood, and agree to the terms of this Policy.
</p>
<h3>
    1. Information Collection
</h3>
<p>
We may collect information that You provide when using the Platform, including but not limited to your name, contact information, and any data that You upload to the Platform for AI-generated content detection. The purpose of this data collection is to improve the functionality and accuracy of the Platform, provide a personalized user experience, and facilitate the detection and analysis of AI-generated content.
</p>
<h3>
    2. Use of Information
</h3>
<p>
We may use the information collected to develop, maintain, and improve the Platform, including future training of AIs and AI detection models. The data You upload to the Platform may be anonymized and used to enhance the performance and accuracy of the Platform's algorithms. This use aims to ensure that the Platform continues to provide users with reliable and effective tools for detecting AI-generated content.
</p>
<h3>
    3. Information Sharing and Disclosure
</h3>
<p>
Your information will not be shared with anyone other than internal employees or shareholders of Ackergill Institute. Access to the collected data is strictly limited to those who require it to perform their job functions and develop the Platform's AI models. We do not sell, rent, or otherwise disclose Your personal information to third parties for their independent use without Your consent, unless required by law.
</p>
<h3>
    4. Data Security
</h3>
<p>
We implement various security measures to protect the integrity and security of Your information. These measures include but are not limited to encryption, firewalls, and secure socket layer technology. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
</p>
<h3>
    5. Changes to the Privacy Policy
</h3>
<p>
We reserve the right to change this Privacy Policy at any time. If we make changes, we will post the updated Policy on this page and revise the "Effective Date" at the top. If we make significant changes, we will notify You through the Platform or by sending You an email. Your continued use of the Platform after such notification constitutes Your acceptance of the updated Policy.
</p>
<h3>
    6. Contact Us
</h3>
<p>
If You have any questions about this Policy or our handling of Your information, please contact us at privacy@ackergillinstitute.ai.
</p>
<p>
By accessing or using the Ackergill Institute AI Detection Platform, you acknowledge that you have read, understood, and agreed to this Privacy Policy.
</p>
        </div>
    );
};

export default PrivacyPolicy;