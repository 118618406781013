import React from 'react';
import './HeroSection.css';
import content from './content.json';  // Import the JSON file

function HeroSection() {
  return (
    <div className="hero-section">
      <h1>
        <span>{content.fold.titlelineone}</span>
        <span>{content.fold.titlelinetwo}</span>
      </h1>
      <a className="link-button hero-button" href={content.fold.buttonurl}>{content.fold.button}</a>
    </div>
  );
}

export default HeroSection;