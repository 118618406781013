import React, { useState } from 'react';
import './Sidebar.css';

function Sidebar() {
  const [activeButton, setActiveButton] = useState('Detect Papers');

  return (
    <div className="sidebar">
      <button 
        className={`sidebar-button ${activeButton === 'Detect Papers' ? 'active' : ''}`}
        onClick={() => setActiveButton('Detect Papers')}
      >
        Detect Papers
      </button>
      <button 
        className={`sidebar-button ${activeButton === 'Billing' ? 'active' : ''}`}
        onClick={() => setActiveButton('Billing')}
      >
        Billing
      </button>
      <button 
        className={`sidebar-button ${activeButton === 'Account' ? 'active' : ''}`}
        onClick={() => setActiveButton('Account')}
      >
        Account
      </button>
    </div>
  );
}

export default Sidebar;