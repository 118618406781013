import React from 'react';
import Sidebar from './Sidebar';
import DashboardContent from './DashboardContent';

const Dashboard = () => {
  return (
    <>
      <Sidebar />
      <DashboardContent />
    </>
  );
}

export default Dashboard;