import React, { useState } from 'react';
import './FAQ.css';
import ScrollTrigger from 'react-scroll-trigger';
import content from './content.json';  // Import the JSON file

const FAQ = () => {
    const [animationClass, setAnimationClass] = useState(['', '', '', '']);

    const handleEnter = () => {
        setAnimationClass(['animate-left', 'animate-right', 'animate-left', 'animate-right']);
    };

    return (
        <ScrollTrigger onEnter={handleEnter}>
            <div className="faq-section">
                <div className="faq-title">
                    <h1>{content.faq.title1}</h1> 
                    <h2>{content.faq.title2}</h2>
                </div>
                <div className="faq-cards">
                    <div className="faq-row">
                        {/* The first two cards */}
                        <div className={`faq-card ${animationClass[0]}`}>
                            <h3>{content.faq.questions[0].question}</h3>
                            <p>{content.faq.questions[0].answer}</p>
                        </div>
                        <div className={`faq-card ${animationClass[1]}`}>
                            <h3>{content.faq.questions[1].question}</h3>
                            <p>{content.faq.questions[1].answer}</p>
                        </div>
                    </div>
                    <div className="faq-row">
                        {/* The last two cards */}
                        <div className={`faq-card ${animationClass[2]}`}>
                            <h3>{content.faq.questions[2].question}</h3>
                            <p>{content.faq.questions[2].answer}</p>
                        </div>
                        <div className={`faq-card ${animationClass[3]}`}>
                            <h3>{content.faq.questions[3].question}</h3>
                            <p>{content.faq.questions[3].answer}</p>
                        </div>
                    </div>
                </div>
            </div>
        </ScrollTrigger>
    );
};

export default FAQ;