import React, { useState } from 'react';
import './Explainer.css';
import ScrollTrigger from 'react-scroll-trigger';
import content from './content.json';

function Explainer() {
  const [animationClass, setAnimationClass] = useState(['', '', '']);

  const handleEnter = () => {
    setAnimationClass(['animate-left', 'animate-up', 'animate-right']);
  };

  return (
    <ScrollTrigger onEnter={handleEnter}>
      <div className="explainer-section">
        <h2 className="explainer-title">{content.explainer.title}</h2>
        <div className="explainer-container">
          <div className={`svg-container ${animationClass[0]}`}>
            <img src={process.env.PUBLIC_URL + '/vendor/Funnel_Art.svg'} alt="Funnel Art"/>
          </div>
          <div className={`text-container ${animationClass[1]}`}>
            <p>{content.explainer.BulletPointoneContent}</p>
            <p>{content.explainer.BulletPointtwoContent}</p>
            <p>{content.explainer.BulletPointthreeContent}</p>
          </div>
          <div className={`box-container ${animationClass[2]}`}>
            <h3>Driven by the Unparalleled Excellence of State-of-the-Art AI Detection</h3>
            <p>{content.explainer.ParagraphoneBox}</p>
            <p>{content.explainer.ParagraphtwoBox}</p>
            <p>{content.explainer.ParagraphthreeBox}</p>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
}

export default Explainer;