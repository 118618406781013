import React from 'react';
import './TOS.css'; // Import the CSS file here
import Footer from './Footer';

const TOS = () => {
    return (
        <div className="tos">
            <h1>Sign Up</h1>
            <p>
            Given that we are currently in our beta testing phase, we kindly ask you to correspond via email at 
            </p>
            <p>
            support@ackergill-institute.ai
            </p>
            <p>
            This practice is enforced to ensure the utmost security for our users and organizations against potential data breaches and associated risks.
            </p>
            <p>
            If your payment has been processed through Stripe, please anticipate your account activation and a confirmation email within the next 24 to 48 hours.
            </p>
        </div>
    );
};

export default TOS;