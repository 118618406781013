import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './Home';
import Dashboard from './Dashboard';
import Header from './Header';
import Team from './team';
import Login from './login';  // import Login component
import UserContext from './UserContext'; // import UserContext
import TOS from './TOS';
import SignUp from './SignUp';
import PrivacyPolicy from './PrivacyPolicy';

function App() {
  const [user, setUser] = useState(null); // create a state for user

  return (
    <div className="App">
      <Helmet>
      </Helmet>
      <UserContext.Provider value={{ user, setUser }}> 
        <BrowserRouter>
          <Header />  {/* Header should be inside BrowserRouter */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={user ? <Navigate to="/app/dashboard" /> : <Login />} /> 
            <Route path="/app/dashboard" element={!user ? <Navigate to="/login" /> : <Dashboard />} />
            <Route path="/team" element={<Team />} />  
            <Route path="/tos" element={<TOS />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          </Routes>
        </BrowserRouter>
      </UserContext.Provider>
    </div>
  );
}

export default App;