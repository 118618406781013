import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from './UserContext';
import accounts from './login.json'; // import the accounts
import './login.css';

const Login = () => {
    const navigate = useNavigate();
    const { setUser } = useContext(UserContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        // check if the user exists
        const user = accounts.find(
            (account) => account.email === email && account.password === password
        );

        if (user) {
            // if user exists, set the user and navigate to dashboard
            setUser(user);
            navigate('/app/dashboard');
        } else {
            // show an error message
            alert('Invalid email or password');
        }
    };

    return (
        <div className="login-page">
            <div className="login-image">
                <img src={process.env.PUBLIC_URL + '/vendor/blur.png'} alt="Background" />
            </div>
            <div className="login-form">
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                    <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                    <button type="submit">Submit</button>
                </form>
                <p>Don't have an account? <a href="/signup">Create one today!</a></p>
            </div>
        </div>
    );
};

export default Login;