import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Plans.css';
import ScrollTrigger from 'react-scroll-trigger';
import content from './content.json';  // Import the JSON file

function Plans() {
  const [animationClass, setAnimationClass] = useState(['', '', '']);
  const navigate = useNavigate();

  const handleEnter = () => {
    setAnimationClass(['animate-left', 'animate-up', 'animate-right']);
  };

  const handlePlanClick = (link) => {
    navigate(link);
  };

  return (
    <div>
      <h1 className="plans-title">{content.Plans.title}</h1>
      <ScrollTrigger onEnter={handleEnter}>
        <div className="plans-container">
          {content.Plans.plans.map((plan, i) => (
            <div 
              key={i} 
              className={`plan-card ${i === 1 ? 'plan-card-large' : ''} ${animationClass[i]}`} 
              onClick={() => handlePlanClick(plan.link)}
            >
              <h2 className="plan-title">{plan.title}</h2>
              <h3 className="plan-price">{plan.price}</h3>
              <ul className="plan-features">
                {plan.features.map((feature, j) => (
                  <li key={j} className="plan-feature">{feature}</li>
                ))}
              </ul>
              <p className="plan-description">{plan.description}</p>
            </div>
          ))}
        </div>
      </ScrollTrigger>
    </div>
  );
}

export default Plans;