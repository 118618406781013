import React from 'react';
import './TOS.css'; // Import the CSS file here
import Footer from './Footer';

const TOS = () => {
    return (
        <div className="tos">
            <h1>Terms of Service for Ackergill Institute</h1>
            <p>
            Effective Date: [June 8, 2023]
            </p>
            <p>
            Please read these Terms of Service ("Terms") carefully before accessing or using the Ackergill Institute AI Detection Platform ("Platform"). These Terms constitute a legally binding agreement between you ("User," "You," or "Your") and Ackergill Institute ("Ackergill," "We," "Us," or "Our") regarding Your use of the Platform.
            </p>
            <p>
            By accessing or using the Platform, You acknowledge that You have read, understood, and agree to be bound by these Terms. If You do not agree with any provisions of these Terms, You may not access or use the Platform.
            </p>
            <h3>
                1. Platform Description
            </h3>
            <p>
            The Ackergill Institute AI Detection Platform for AI-Generated Content Detection is designed to assist users in detecting AI-generated content, such as essays or written assignments. The Platform utilizes advanced algorithms and methodologies to identify and flag content that may have been generated or assisted by AI systems.
            </p>
            <h3>
                2. Ackergill's Role
            </h3>
            <p>
            Ackergill Institute acts as a provider of the Platform and related services, which include but are not limited to hosting, maintenance, and support. We make no warranties or representations regarding the accuracy, completeness, or reliability of the Platform's output, and You acknowledge and accept that the Platform's results should be used as a tool to aid decision-making rather than as a definitive judgment.
        </p>
        <h3>
                3. Limitation of Liability
            </h3>
            <p>
            a. Billing Issues: Ackergill Institute shall not be held liable for any billing issues, including but not limited to incorrect billing amounts, double billing, or other related discrepancies. It is Your responsibility to review and verify all billing information before making any financial decisions or commitments.
        </p>
        <p>
        b. False AI Positives or Negatives: While Ackergill Institute strives for accuracy, the Platform's outputs may occasionally contain false AI positives or negatives. You agree that Ackergill Institute shall not be held liable for any losses, damages, or liabilities arising from such false detections. It is Your responsibility to review and verify the Platform's outputs and take appropriate actions based on the context of each detection.
        </p>
        <p>
        c. Other Liabilities: Ackergill Institute disclaims all liability for any other major liabilities, including but not limited to the failure of the Platform to detect or identify certain AI-generated content, errors or omissions in the Platform's results, or any other unforeseen circumstances. You acknowledge and accept that the Platform's performance is subject to inherent limitations and that Ackergill Institute cannot guarantee error-free or uninterrupted operation.
</p>
        <h3>
                4. Indemnification
            </h3>
            <p>
            You agree to indemnify, defend, and hold harmless Ackergill Institute, its officers, directors, employees, agents, and affiliates from and against any claims, liabilities, damages, losses, costs, or expenses (including reasonable attorneys' fees) arising out of or related to Your use of the Platform, violation of these Terms, or infringement of any third-party rights.
</p>
        <h3>
                5. User Responsibility
            </h3>
            <p>
            a. User Conduct: You are solely responsible for Your conduct while using the Platform. You agree to comply with all applicable laws, regulations, and ethical guidelines regarding the use of the Platform. You further acknowledge that the Platform's results should be used as a tool to aid in academic integrity and not as a substitute for proper evaluation and judgment.
</p>
<p>
b. Data Privacy: Ackergill Institute values the privacy of Your data. However, You acknowledge and agree that Ackergill Institute may collect, use, and disclose certain information as described in the Privacy Policy. It is Your responsibility to comply with all applicable data protection laws and regulations when using the Platform.
</p>
        <h3>
                6. Governing Law and Dispute Resolution
            </h3>
            <p>
            These Terms shall be governed by and construed in accordance with the laws of Canada. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of Canada.</p>
        <h3>
                7. Modifications to the Terms
            </h3>
            <p>
            Ackergill Institute reserves the right to modify or update these Terms at any time without prior notice. Any changes to the Terms will be effective upon posting the revised version on the Platform. Your continued use of the Platform after any modifications to the Terms constitutes Your acceptance of the revised Terms.
</p>
        <h3>
                8. Severability
            </h3>
            <p>
            If any provision of these Terms is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity shall not render these Terms unenforceable or invalid as a whole. Instead, the provision shall be interpreted and enforced to the maximum extent permitted by law to reflect the original intent of the parties.
</p>
<h3>
                9. Entire Agreement
            </h3>
            <p>
            These Terms constitute the entire agreement between You and Ackergill Institute regarding Your use of the Platform and supersede all prior or contemporaneous agreements, understandings, and communications, whether written or oral, relating to the subject matter herein.
</p>
<p>
If you have any questions or concerns about these Terms, please contact us at legal@ackergillinstitute.ai.</p>
<p>
By accessing or using the Ackergill Institute AI Detection Platform for AI-Generated Content Detection, you acknowledge that you have read, understood, and agreed to these Terms of Service.
</p>
        </div>
    );
};

export default TOS;