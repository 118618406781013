import React, { useState } from 'react';
import { Link } from 'react-router-dom';  // Import the Link component
import content from './content.json';
import './Header.css';

function Header() {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  return (
    <header className="header">
      <Link to="/" className="logo">{content.Header.logo}</Link>  {/* Replace h2 with Link */}
      <div className="links-wrapper">
        <nav>
          <ul className={`nav-links ${dropdownVisible ? 'visible' : ''}`}>
            {content.Header.links.map((link, index) => (
              <li key={index}>
                <a href={link.url} className={link.isButton ? 'link-button' : ''}>{link.title}</a>
              </li>
            ))}
          </ul>
          <button className="dropdown-button" onClick={() => setDropdownVisible(!dropdownVisible)}>Menu</button>
        </nav>
      </div>
    </header>
  );
}

export default Header;