import React from 'react';
import './team.css';
import Footer from './Footer';
import content from './content.json';

function Team() {
  return (
    <>
      <div className="center">
      <h2 className="team-title">{content.team.title}</h2>
        <p className="paragraph">{content.team.one}</p>
        <p className="paragraph">{content.team.two}</p>
        <p className="paragraph">{content.team.three}</p>
        <p className="paragraph">{content.team.four}</p>
      </div>
      <Footer />  // Include the Footer component
    </>
  );
}

export default Team;