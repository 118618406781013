import React from 'react';
import content from './content.json';
import './Footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-text">
          <h1>{content.Footer.title}</h1>
          <p>{content.Footer.description}</p>
          <div className="footer-copyright">
            <p>{content.Footer.copyright}</p>
          </div>
        </div>
        <div className="footer-links">
          {content.Footer.linkSections.map((linkSection, i) => (
            <div key={i}>
              <h3>{linkSection.sectionTitle}</h3>
              <ul>
                {linkSection.links.map((link, j) => (
                  <li key={j}>
                    <a href={link.url}>{link.text}</a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;