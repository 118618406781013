import React from 'react';
import './Home.css'; // Import the CSS file here
import HeroSection from './HeroSection';
import AboutSection from './AboutSection';
import WhatweDoSection from './WhatweDoSection';
import Explainer from './Explainer';
import Plans from './Plans';
import FAQ from './FAQ';
import Footer from './Footer';

const Home = () => {
    return (
        <>
            <HeroSection />
            <div style={{ height: '100vh' }}></div> {/* This is the spacer */}
            <AboutSection />
            <WhatweDoSection />
            <div className="background-section">
                <Explainer />
                <Plans />
            </div>
            <FAQ />
            <Footer />
        </>
    );
};

export default Home;